// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require('jquery')
import 'popper.js'; // Import Popper.js
import 'bootstrap'; // Import Bootstrap (which depends on Popper.js)
import 'owl.carousel/dist/owl.carousel.min.js'; // Import Owl Carousel JS
import 'owl.carousel/dist/assets/owl.carousel.min.css'; // Import Owl Carousel CSS
import 'owl.carousel/dist/assets/owl.theme.default.min.css'; // Import Owl Carousel theme CSS
import "trix";
import "@rails/actiontext";
import "./../theme"
//= require cocoon
// Import Stimulus and initialize it
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Initialize Stimulus
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

$(document).ready(function () {
  $('.vendor-carousel').owlCarousel({
    loop: true,
    margin: 29,
    nav: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
        0:{
            items:2
        },
        576:{
            items:3
        },
        768:{
            items:4
        },
        992:{
            items:5
        },
        1200:{
            items:6
        }
    }
  });
});

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

document.addEventListener("turbolinks:load", function () {
        const script = document.createElement("script");
        script.src = "https://cdn.gtranslate.net/widgets/latest/ln.js";
        script.defer = true;
        document.body.appendChild(script);
});
